import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ModalConfirm } from "tamtam-components";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import parse from "html-react-parser";
import classnames from "classnames";

import _ from "../../../i18n";
import { AGREATION_OPTIONS, AGREATION_INPUT_SIZE } from "../../../config";
import { validateUen, formatUen } from "../../../utils";
import { getFolderByUen, getFolderInfoByUen } from "../../../api";

import InlineAgreationNumber from "../../common/AgreationNumber/InlineAgreationNumber";
import { CheckboxCard, FormInput } from "../../";

import styles from "../CreateOffice.module.scss";

const StepOne = ({
  data,
  setData,
  errors,
  setErrors,
  isExistFiduciary,
  setIsExistFiduciary,
  isOeccbbMember,
}) => {
  // const size = ["1-4", "5-9", "10-14", "15-19", "20-25", "25"];

  const [isFetching, setFetching] = useState(false);
  const [address1, setAddress1] = useState("");
  const [agreation, setAgreation] = useState();
  const [existOfficeSize, setExistOfficeSize] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const clientToken = useSelector((state) => state.auth.clientToken);
  const folder = useSelector((state) => state.folder);
  const [size, setSize] = useState([]);
  const [sizeScroll, setSizeScroll] = useState("RIGHT");

  useEffect(() => {
    let tab = [];
    for (let i = 1; i <= 12; i++) {
      tab.push(i + "-" + i * 4);
    }
    setSize([...size, ...tab]);
    if (data.type) {
      setAgreation(AGREATION_OPTIONS.filter((i) => i.value === data.type)[0]);
    }
    if (folder.fiduciary) {
      setData({
        ...data,
        id: folder.fiduciary.id,
        uen: folder.fiduciary.uen,
        name: folder.fiduciary.name,
        address1: folder.fiduciary.address1,
        zipCode: folder.fiduciary.zipCode,
        city: folder.fiduciary.city,
        uaStatus: "PENDING",
        // status: folder.fiduciary.status,
        type: folder.fiduciary.officeType
          ? folder.fiduciary.officeType
          : "ITAA",
        size: !isOeccbbMember
          ? folder.fiduciary.officeSize
            ? folder.fiduciary.officeSize
            : ""
          : data.size,
        approval: folder.fiduciary.approvalNumber
          ? folder.fiduciary.approvalNumber
          : "",
      });
      setAddress1(folder.fiduciary.address1);
    } else {
      if (data.address1) {
        setAddress1(data.address1);
      }
    }
  }, []);

  const handleClickSize = (size) => {
    if (isOeccbbMember) {
      return null;
    }
    if (!isExistFiduciary || (isExistFiduciary && !existOfficeSize)) {
      setData({ ...data, size: size });
      setErrors({ ...errors, size: "" });
    }
  };

  const handleUenKeyUp = (name, value) => {
    let error = "";
    if (!validateUen(value)) {
      error = _("validate_uen");
    } else {
      error = "";
    }
    if (value.length === 0) {
      error = _("required_field");
    }
    setErrors({ ...errors, [name]: error });
  };

  const getCity = (addresses) => {
    if (addresses && addresses[0]["city"]) {
      return addresses[0]["city"];
    } else if (addresses[0]["municipality"]) {
      return addresses[0]["municipality"];
    } else {
      return "";
    }
  };

  const getAddress = (addresses) => {
    return addresses
      ? addresses[0]["street"] + " " + addresses[0]["house_number"]
      : "";
  };

  const handleUenBlur = (value) => {
    if (validateUen(value)) {
      setErrors({ ...errors, uen: "" });
      setFetching(true);
      getFolderByUen({
        token: clientToken,
        uen: value,
      })
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            setIsOpenConfirmModal(true);
            let agreationType = "ITAA";
            if (response.data.data[0].officeType) {
              let ag = AGREATION_OPTIONS.filter(
                (i) => i.value === response.data.data[0].officeType
              );
              if (ag && ag.length === 1) {
                // agreationType = ag[0];
                agreationType = response.data.data[0].officeType;
                setAgreation(ag[0]);
              } else {
                setAgreation(AGREATION_OPTIONS[0]);
              }
            }
            setData({
              ...data,
              id: response.data.data[0].id,
              uen: formatUen(value),
              name: response.data.data[0].name,
              address1: response.data.data[0].address1,
              zipCode: response.data.data[0].zipCode,
              city: response.data.data[0].city,
              status: response.data.data[0].status,
              type: agreationType,
              size: !isOeccbbMember
                ? response.data.data[0].officeSize
                  ? response.data.data[0].officeSize
                  : ""
                : data.size,
              approval: response.data.data[0].approvalNumber
                ? response.data.data[0].approvalNumber
                : "",
            });
            setExistOfficeSize(response.data.data[0].officeSize ? true : false);
            setAddress1(response.data.data[0].address1);
            setErrors({ ...errors, name: "" });
          }
          setFetching(false);
        })
        .catch((e) => {
          setIsExistFiduciary(false);
          if (e.response && e.response.status === 404) {
            getFolderInfoByUen({
              token: clientToken,
              uen: value.replace(
                /(BE|be)? ?(\d{4})[. ]?(\d{3})[. ]?(\d{3})/,
                "BE$2$3$4"
              ),
            })
              .then((res) => {
                setFetching(false);
                const resp = res.data.data;
                if (resp.isValid) {
                  const address = resp.address.split("\n");
                  const addressStr =
                    address.length > 0 ? address[0] : resp.address;
                  setData({
                    ...data,
                    uen: formatUen(value),
                    name: resp.name,
                    address1: addressStr,
                  });
                  setAddress1(addressStr);
                  setErrors({ ...errors, name: "" });
                } else {
                  setData({ ...data, uen: formatUen(value) });
                }
              })
              .finally(() => {
                setFetching(false);
              });
          } else {
            setFetching(false);
            setData({ ...data, uen: formatUen(value) });
          }
        });
      // .finally(() => {
      //   setFetching(false);
      //   setData({ ...data, uen: formatUen(value) });
      // });
    } else {
      if (value.length === 0) {
        setErrors({ ...errors, uen: _("required_field") });
      } else {
        setErrors({ ...errors, uen: _("validate_uen") });
      }
    }
  };

  const handleBlur = (name, value) => {
    if (value.length === 0) {
      setErrors({ ...errors, [name]: _("required_field") });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleChange = (name, value) => {
    if (value.length === 0) {
      setErrors({ ...errors, [name]: _("required_field") });
    } else {
      setErrors({ ...errors, [name]: _("") });
    }
    setData({ ...data, [name]: value });
  };

  const handleAddressChange = (address) => {
    if (address.length === 0) {
      setErrors({ ...errors, address1: _("required_field") });
    } else {
      setErrors({ ...errors, address1: "" });
    }
    setData({ ...data, address1: address });
    setAddress1(address);
  };

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => results[0])
      .then((results) => {
        const addressComponents = results.address_components;
        const zip_code = addressComponents.filter((address) =>
          address.types.includes("postal_code")
        );
        const comp_city = addressComponents.filter(
          (address) =>
            address.types.includes("political") &&
            address.types.includes("locality")
        );
        const state = addressComponents.filter(
          (address) =>
            address.types.includes("political") &&
            address.types.includes("administrative_area_level_1")
        );
        const country = addressComponents.filter(
          (address) =>
            address.types.includes("political") &&
            address.types.includes("country")
        );
        const city = comp_city.length > 0 ? comp_city[0].long_name : "";
        const zipCode = zip_code.length > 0 ? zip_code[0].long_name : "";
        let cleanAdd = address;
        if (country.length > 0)
          cleanAdd = cleanAdd.replace(", " + country[0].long_name, "");
        if (state.length > 0)
          cleanAdd = cleanAdd.replace(", " + state[0].long_name, "");
        if (city.length > 0) cleanAdd = cleanAdd.replace(", " + city, "");

        setData({
          ...data,
          city,
          zipCode,
          address1: cleanAdd,
        });
        setAddress1(cleanAdd);
        setErrors({
          ...errors,
          zipCode: zipCode ? "" : _("required_field"),
          city: city ? "" : _("required_field"),
        });
      })
      .catch((error) => console.error("Error", error));
  };

  const formatSize = (size) => {
    const tab = size.split("-");
    return tab.length === 2 ? `${tab[0]}+${tab[1]}` : tab[0];
  };

  const handleCancelExistAction = () => {
    setIsOpenConfirmModal(false);
    setData({
      ...data,
      id: null,
      uen: "",
      name: "",
      address1: "",
      zipCode: "",
      city: "",
      status: "",
      size: "",
      approval: "",
    });
    setAddress1("");
  };

  const handleConfirmExistAction = () => {
    setIsExistFiduciary(true);
    setIsOpenConfirmModal(false);
  };

  const handleAgreation = (e) => {
    setAgreation(e);
    const d = { ...data, type: e.value };
    if (e.value === "UNIVERSITY") {
      d.size = "";
    }
    setData(d);
  };

  const handleNumberAgreation = (e) => {
    setData({ ...data, approval: e });
    setErrors({
      ...errors,
      type: !isValidType(e) ? _("required_field") : "",
    });
  };

  const isValidType = (value) => {
    return (
      !["ITAA", "IRE_AUDIT"].includes(data.type) ||
      (["ITAA", "IRE_AUDIT"].includes(data.type) &&
        AGREATION_INPUT_SIZE[data.type] === value.length)
    );
  };

  const handleNextSize = () => {
    let lastSize = size[size.length - 1];
    lastSize = lastSize.split("-");

    const min = parseInt(lastSize[0]) + 1;
    const newSize = min + "-" + min * 4;
    setSize([...size, newSize]);

    const el = document.getElementById("list");
    el.scrollLeft += 300;
    if (el.scrollLeft + 0.5 === el.scrollWidth - el.offsetWidth) {
      setSizeScroll("LEFT");
    } else if (el.scrollLeft > 0) {
      setSizeScroll("BOTH");
    }
  };

  return (
    <>
      {isFetching && <div className="lmask"></div>}
      <div className={styles.body}>
        <div className="grid-x grid-margin-x">
          <div className="cell small-12 large-3">
            <FormInput
              name="uen"
              required={data.type !== "UNIVERSITY" ? true : false}
              placeholder="BE XXXX.XXX.XXX"
              label={_("business_number")}
              info={_("uen_info")}
              error={errors.uen}
              value={data.uen}
              autoComplete="none"
              onKeyUp={(e) => handleUenKeyUp(e.target.name, e.target.value)}
              handleBlur={(e) => handleUenBlur(e.target.value)}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            ></FormInput>
          </div>

          <div className="cell small-12 large-3">
            <FormInput
              name="name"
              value={data.name}
              required={true}
              label={_("company_name")}
              error={errors.name}
              autoComplete="off"
              handleBlur={(e) => handleBlur(e.target.name, e.target.value)}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              disabled={isExistFiduciary}
            ></FormInput>
          </div>
          <div className="cell small-12 large-6">
            <div class="FormInput_group__CrYQj">
              <InlineAgreationNumber
                NumeroAgreation={data.approval}
                Agreation={agreation}
                setAgreation={handleAgreation}
                setNumeroAgreation={handleNumberAgreation}
                small={true}
                error={errors.type}
              />
            </div>
          </div>
        </div>

        <hr className={styles.separator} />

        <div className="grid-x grid-margin-x">
          <div className="cell small-12 large-6">
            <label className="ttp-label">
              {_("address")}
              <span className="star">*</span>
            </label>
            <PlacesAutocomplete
              value={address1}
              onChange={handleAddressChange}
              onSelect={handleSelect}
              searchOptions={{
                componentRestrictions: { country: ["be"] },
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="group-input">
                  <input
                    {...getInputProps({
                      placeholder: "Search Places ...",
                      className: classnames(
                        "location-search-input",
                        errors.address1 ? "error" : ""
                      ),
                    })}
                    autoComplete="none"
                    name="address1"
                    disabled={isExistFiduciary}
                    // onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                  />
                  <span className="ttp-error">{errors.address1}</span>
                  {suggestions.length > 0 ? (
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          <div className="cell small-12 large-3">
            <FormInput
              name="zipCode"
              label={_("zipCode")}
              required={true}
              value={data.zipCode}
              error={errors.zipCode}
              autoComplete="none"
              disabled={isExistFiduciary}
              handleBlur={(e) => console.log(data)}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            ></FormInput>
          </div>
          <div className="cell small-12 large-3">
            <FormInput
              name="city"
              label={_("city")}
              required={true}
              value={data.city}
              error={errors.city}
              autoComplete="none"
              disabled={isExistFiduciary}
              // handleBlur={(e) => requiredField(e)}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            ></FormInput>
          </div>
        </div>

        {data.type !== "UNIVERSITY" && (
          <>
            <hr className={styles.separator} />

            <label className={styles.label}>
              {_("number_collaborator")} <span className="star">*</span>
            </label>

            <div className={styles.sizeBar}>
              {(sizeScroll === "LEFT" || sizeScroll === "BOTH") && (
                <div
                  className={classnames(
                    styles.scrollButton,
                    styles.scrollButtonBack
                  )}
                >
                  <button
                    onClick={() => {
                      const el = document.getElementById("list");
                      el.scrollLeft -= 300;
                      if (el.scrollLeft === 0) {
                        setSizeScroll("RIGHT");
                      } else if (el.scrollLeft > 0) {
                        setSizeScroll("BOTH");
                      }
                    }}
                  >
                    <i className="icon-ttp-chevron-up" />
                  </button>
                </div>
              )}

              <ul id="list">
                {size.map((s, i) => (
                  <li key={`size-${i}`}>
                    <CheckboxCard
                      disabled={isOeccbbMember}
                      onClick={() => handleClickSize(s)}
                      selected={data.size === s}
                      icon="/img/collaborators.png"
                      html={parse(
                        `<span>${formatSize(s)}</span> ${_("Collab.")}`
                      )}
                    />
                  </li>
                ))}
              </ul>

              <div
                className={classnames(
                  styles.scrollButton,
                  styles.scrollButtonForward
                )}
              >
                <button
                  onClick={() => {
                    handleNextSize();
                  }}
                >
                  <i className="icon-ttp-chevron-up" />
                </button>
              </div>
            </div>
            <span className={styles.errorCol}>{errors.size}</span>
          </>
        )}
      </div>
      <ModalConfirm
        type="duplicate"
        isOpen={isOpenConfirmModal}
        onCancel={() => {
          handleCancelExistAction();
        }}
        onConfirm={() => handleConfirmExistAction()}
        // inProcess={deleting}
        title={_("title_exist_fiduciary_confirm")}
        text={_("text_exist_fiduciary")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
    </>
  );
};

export default StepOne;
