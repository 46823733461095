import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import styles from "./NewsletterModal.module.scss";

import _ from "../../../i18n";
import Button from "../Button";
import classnames from "classnames";
import PlansNewsletter from "./PlansNewsletter";
import NewsletterSettings from "./NewsletterSettings";
import IconClose from "../icons/IconClose";
import Tippy from "@tippyjs/react";
import { useQuery } from "react-query";
import {
  getCollaborators,
  getIdentitiesVerificationStatus,
  saveAppsSettings,
} from "../../../api";
import { DAYS } from "../../../config";
import { toast } from "react-toastify";
import { setFiduciary } from "../../../store";
import classNames from "classnames";

const NewsletterModal = ({ isOpen, setIsOpen, title, inProcess }) => {
  const dispatch = useDispatch();
  const fiduciary = useSelector(state => state.folder.fiduciary);
  const auth = useSelector(state => state.auth);
  const { token } = auth;
  const [tools, setTools] = useState([]);
  const [newsletterPlan, setNewsletterPlan] = useState("LITE");
  const [frequency, setFrequency] = useState({
    value: "BY_MONTH",
    label: _("by_month"),
  });
  const [slide, setSlide] = useState(1);
  const [selectedDay, setSelectedDay] = useState(DAYS[0]);
  const [isAutomaticNewsletter, setIsAutomaticNewsletter] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedHour, setSelectedHour] = useState({
    value: "00",
    label: "00",
  });
  const [selectedMinutes, setSelectedMinutes] = useState({
    value: "00",
    label: "00",
  });
  const [sort, setSort] = useState({
    property: "lastName",
    dir: "asc",
  });
  const [senders, setSenders] = useState([]);
  const [hasPlans, setHasPlans] = useState(false);
  const [verifiedEmailsList, setVerifiedEmailsList] = useState([]);
  const [isVerifiedEmailsLoading, setIsVerifiedEmailsLoading] = useState(false);
  const [isLogoUaVisible, setIsLogoUaVisible] = useState(true);
  const [basicPlan, setBasicPlan] = useState("");

  const preferences = [
    {
      index: "frequency",
      details: [
        {
          option: _("articles_option_3"),
          desc: _("frequency_desc"),
        },
      ],
    },
    {
      index: "recipients",
      details: [
        {
          option: _("recipient"),
          desc: _("recipients_desc"),
        },
      ],
    },
    {
      index: "senders",
      details: [
        {
          option: _("senders"),
          desc: _("senders_desc"),
        },
      ],
    },
    {
      index: "articles",
      desc: _("articles_option_title"),
      details: [
        {
          option: _("articles_option_1"),
          desc: _("fff_news_desc"),
        },
        {
          option: _("articles_option_2"),
          desc: _("my_pub_desc"),
        },
      ],
    },
    {
      index: "customization",
      desc: _("customization_option_title"),
      details: [
        {
          option: _("customization_option_1"),
          desc: _("pub_logo_desc"),
        },
        {
          option: _("customization_option_2"),
          desc: _("theme_desc"),
        },
      ],
    },
  ];

  const frequency_tabs = [
    { value: "BY_WEEK", label: _("by_week") },
    { value: "BY_TWO_WEEKS", label: _("by_two_weeks") },
    { value: "BY_MONTH", label: _("by_month") },
  ];

  useEffect(
    () => {
      if (fiduciary) {
        const preferences = fiduciary[`newsletterPreferences`];
        if (preferences && preferences.apps) {
          setTools(preferences.apps);
          preferences.apps.map(
            t =>
              t.type === "BLOG_NL_CREATE" &&
              (setHasPlans(t.config ? "true" : "false"),
                setNewsletterPlan(t.config ? t.config : "LITE"),
                setBasicPlan(t.basicPlan ? t.basicPlan : ""),
                /* setFrequency({
                      value: t.frequency ? t.frequency : "BY_WEEK",
                      label: t.frequency
                        ? _(t.frequency.toLowerCase())
                        : _("by_week"),
                    }),*/
                setFrequency({
                  value:
                    t.config && t.config !== "PERSONALIZED"
                      ? "BY_MONTH"
                      : "BY_WEEK",
                  label:
                    t.config && t.config !== "PERSONALIZED"
                      ? _("by_month")
                      : _("by_week"),
                }),
                setSelectedWeek({
                  value: t.selectedWeek ? t.selectedWeek : "FIRST_WEEK",
                  label: t.selectedWeek
                    ? t.frequency === "BY_TWO_WEEKS"
                      ? t.selectedWeek === "FIRST_WEEK"
                        ? _("week_desc_1")
                        : _("week_desc_2")
                      : _(t.selectedWeek.toLowerCase())
                    : _("first_week"),
                }),
                setSenders(t.senders ? t.senders : []),
                setIsAutomaticNewsletter(t.isAutomatic ? t.isAutomatic : false),
                setSelectedDay(
                  t.day ? DAYS.filter(item => item.value === t.day)[0] : DAYS[0],
                ),
                setSelectedHour({
                  value: t.hour ? t.hour : "00 AM",
                  label: t.hour ? t.hour : "00 AM",
                }),
                setSelectedMinutes({
                  value: t.minute ? t.minute : "00",
                  label: t.minute ? t.minute : "00",
                }),
              t.isLogoUaVisible && setIsLogoUaVisible(t.isLogoUaVisible)),
          );
        } else setTools([]);
      } else {
        setTools([]);
      }
    },
    [fiduciary],
  );

  useEffect(
    () => {
      if (hasPlans) {
        setSlide(2);
      } else {
        setSlide(1);
      }
    },
    [hasPlans],
  );
  useEffect(
    () => {
      if (isOpen) {
        handleFetchVerifiedEmails();
      }
    },
    [isOpen],
  );
  useEffect(
    () => {
      if (verifiedEmailsList && verifiedEmailsList.length > 0) {
        if (senders.length > 0) {
          const updatedSenders = senders.map(sender => {
            const verifiedEmail = verifiedEmailsList.find(
              emailObj => emailObj.email === sender.email,
            );

            if (verifiedEmail) {
              return { ...sender, status: verifiedEmail.VerificationStatus };
            } else {
              return { ...sender, status: "Failed" };
            }
          });
          setSenders(updatedSenders);
        }
      }
    },
    [verifiedEmailsList],
  );

  let { data } = useQuery(
    ["listCollaboratorsNewsletters", token, sort, fiduciary, isOpen],
    async () => {
      if (token && fiduciary && isOpen) {
        const response = await getCollaborators({
          token,
          sort,
          organizationId: fiduciary.id,
          isConfigNewsletter: true,
        });
        return response.data;
      }
    },
  );

  const handleFetchVerifiedEmails = () => {
    setIsVerifiedEmailsLoading(true);
    getIdentitiesVerificationStatus(token).then(resp => {
      setVerifiedEmailsList(
        Object.keys(resp.data.data).map(el => {
          return { email: el, ...resp.data.data[el] };
        }),
      );
      setIsVerifiedEmailsLoading(false);
    });
  };
  const handleCloseNLModal = () => {
    if (
      tools.filter(t => t.type === "BLOG_NL_CREATE" && t.config === null)
        .length > 0 &&
      tools.filter(t => t.type === "BLOG_NL_CREATE").length > 0
    ) {
      setTools(tools.filter(t => t.type !== "BLOG_NL_CREATE"));
    }
    setIsOpen(false);
  };

  const handleSave = () => {
    let toolsTabs = [];

    if (newsletterPlan) {
      if (tools.length > 0) {
        toolsTabs.push(
          ...tools.map(
            tool =>
              tool.type === "BLOG_NL_CREATE"
                ? {
                  ...tool,
                  config: newsletterPlan,
                  frequency: frequency.value,
                  selectedWeek: selectedWeek ? selectedWeek.value : "",
                  senders: senders,
                  isAutomatic: isAutomaticNewsletter,
                  day: selectedDay.value,
                  hour: selectedHour.value,
                  minute: selectedMinutes.value,
                  isLogoUaVisible: isLogoUaVisible,
                }
                : tool,
          ),
        );
      } else {
        toolsTabs.push({
          type: "BLOG_NL_CREATE",
          config: newsletterPlan,
          frequency: frequency.value,
          selectedWeek: selectedWeek ? selectedWeek.value : "",
          senders: senders,
          isAutomatic: isAutomaticNewsletter,
          day: selectedDay.value,
          hour: selectedHour.value,
          minute: selectedMinutes.value,
          isLogoUaVisible: isLogoUaVisible,
        });
      }
    }

    setTools(toolsTabs);
    saveAppsSettings({
      token: auth.token,
      communityId: fiduciary.id,
      data: {
        appName: "newsletter",
        tools: toolsTabs,
      },
    })
      .then(resp => {
        toast.success(_("successfully_saved"));
        setIsOpen(false);
        const preferences = fiduciary[`newsletterPreferences`] || {};
        dispatch(
          setFiduciary({
            ...fiduciary,
            [`newsletterPreferences`]: { ...preferences, apps: toolsTabs },
          }),
        );
      })
      .catch(e => {
        toast.error(_("error"));
      });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        inProcess={inProcess}
        ariaHideApp={false}
        onConfirm={handleSave}
        onCancel={handleCloseNLModal}
        className={{
          base: classnames(styles.modalContent),
          afterOpen: styles.modalContentAfterOpen,
          beforeClose: styles.modalContentBeforeClose,
        }}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.container}>
          <div className={styles.modalHeader}>
            <p className={styles.headerTitle}>{title}</p>
            <div className={styles.close} onClick={handleCloseNLModal}>
              <IconClose size={14} />
            </div>
          </div>

          <div
            className={classNames(
              `grid-x ${styles.modalBody}`,
              slide === 1 ? styles.first_slide : styles.second_slide,
            )}
          >
            {slide === 1 ? (
              <>
                <div className={"cell small-12 medium-3"}>
                  <div>
                    <ul>
                      <li>
                        <span>
                          <h3> {_("plans_newsletter_title")} </h3>
                        </span>
                      </li>

                      <li className={styles.desc}>
                        <Tippy
                          content={<span> {_("plans_newsletter_desc")}</span>}
                        >
                          <span className={styles.tooltip}>
                            {_("plans_newsletter_desc")}
                          </span>
                        </Tippy>
                      </li>

                      <div className={styles.preferences}>
                        {preferences.map((preference, index) => (
                          <li key={`${index}`}>
                            {preference.desc && <h4> {preference.desc}: </h4>}
                            <ul>
                              {preference.details.map((detail, i) => (
                                <li
                                  className={styles.options}
                                  key={`pDetail-${i}`}
                                >
                                  <span>
                                    {detail.option}
                                    <Tippy
                                      content={<span> {detail.desc}</span>}
                                    >
                                      <span className={styles.tooltip}>
                                        <i className="icon-ttp-alert-circle" />
                                      </span>
                                    </Tippy>
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </div>
                    </ul>
                  </div>
                </div>
                <PlansNewsletter
                  newsletterPlan={newsletterPlan}
                  handleChangePlan={setNewsletterPlan}
                  frequency={frequency}
                  setFrequency={setFrequency}
                  frequencyTabs={frequency_tabs}
                  basicPlan={basicPlan}
                />
              </>
            ) : (
              <NewsletterSettings
                newsletterPlan={newsletterPlan}
                frequency={frequency}
                setFrequency={setFrequency}
                frequencyTabs={frequency_tabs}
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                isAutomaticNewsletter={isAutomaticNewsletter}
                setIsAutomaticNewsletter={setIsAutomaticNewsletter}
                selectedWeek={selectedWeek}
                setSelectedWeek={setSelectedWeek}
                selectedHour={selectedHour}
                setSelectedHour={setSelectedHour}
                selectedMinutes={selectedMinutes}
                setSelectedMinutes={setSelectedMinutes}
                collaborators={data?.data}
                senders={senders}
                setSenders={setSenders}
                setSlide={setSlide}
                verifiedEmailsList={verifiedEmailsList}
                handleFetchVerifiedEmails={handleFetchVerifiedEmails}
                isVerifiedEmailsLoading={isVerifiedEmailsLoading}
                isLogoUaVisible={isLogoUaVisible}
                setIsLogoUaVisible={setIsLogoUaVisible}
              />
            )}
          </div>
          <hr className={styles.separator} />
          <div className={styles.modalFooter}>
            <div>
              <button
                className={styles.cancelButton}
                onClick={handleCloseNLModal}
              >
                {_("cancel")}
              </button>
            </div>
            <div className={styles.right_block}>
              {slide === 1 && (
                <Button
                  className={styles.configureButton}
                  onClick={() => setSlide(2)}
                >
                  {_("configurer l'envoi automatique ")}
                </Button>
              )}

              <Button
                className={styles.saveButton}
                onClick={() => handleSave()}
              >
                {_("save")}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NewsletterModal;
