import React from "react";
import styles from "./NewsletterModal.module.scss";

import _ from "../../../i18n";
import classNames from "classnames";
import { SELECT_STYLES } from "../../../config";
import Select from "react-select";

const PlansNewsletter = ({
  newsletterPlan,
  handleChangePlan,
  frequency,
  setFrequency,
  frequencyTabs,
  basicPlan,
}) => {
  const plans = [
    {
      index: "LITE",
      title: _("lite"),
      max_articles_fff: _("plans_newsletter_1_max_articles_fff"),
      articles_fff_state: _("predefined"),
      max_my_articles: _("plans_newsletter_1_max_my_articles"),
      logo: true,
      mode: _("unique_mode"),
      frequency_number: 1,
      recipients: 25,
      senders: 1,
      //_("light_mode"),
    },
    {
      index: "STANDARD",
      title: _("standard"),
      max_articles_fff: 4 + " " + _("articles"),
      articles_fff_state: _("predefined"),
      max_my_articles: _("plans_newsletter_1_max_my_articles"),
      logo: true,
      mode: _("unique_mode"),
      frequency_number: 1,
      recipients: 250,
      senders: 1,
      //_("light_mode"),
    },
    /* {
      index: "BASIC",
      title: _("basic"),
      max_articles_fff: _("plans_newsletter_1_max_articles_fff"),
      articles_fff_state: _("predefined"),
      max_my_articles: _("plans_newsletter_1_max_my_articles"),
      logo: true,
      mode: _("unique_mode"),
      //_("light_mode"),
    },*/
    /*{
      index: "EDITO",
      title: _("edito"),
      max_articles_fff: _("plans_newsletter_2_max_articles_fff"),
      articles_fff_state: _("predefined"),
      max_my_articles: _("plans_newsletter_2_max_my_articles"),
      my_articles_state: _("by_choice"),
      logo: true,
      mode: _("unique_mode"),
      //_("light_mode"),
    },*/
    {
      index: "PERSONALIZED",
      title: _("personalized"),
      max_articles_fff: _("plans_newsletter_3_max_articles_fff"),
      articles_fff_state: _("by_choice"),
      max_my_articles: _("plans_newsletter_3_max_my_articles"),
      my_articles_state: _("by_choice"),
      logo: true,
      mode: _("customized_mode"),
      frequency_number: 4,
      recipients: "2500",
      senders: 20,
      //_("light_dark_mode"),
    },
  ];
  const isDisabled = planIndex => {
    if (!basicPlan || basicPlan === "PERSONALIZED") {
      return false;
    }

    return (
      (basicPlan === "LITE" &&
        (planIndex === "PERSONALIZED" || planIndex === "STANDARD")) ||
      (basicPlan === "STANDARD" && planIndex === "PERSONALIZED")
    );
  };

  const handleRadioChange = planIndex => {
    setFrequency(
      planIndex !== "PERSONALIZED"
        ? { value: "BY_MONTH", label: _("by_month") }
        : { value: "BY_WEEK", label: _("by_week") }
    );
    handleChangePlan(planIndex);
  };

  return (
    <>
      <div className={"cell small-12 medium-7"}>
        <div className={"grid-x"}>
          {plans.map((plan, index) => (
            <div
              className={`cell small-12 medium-3 
              ${styles.plans} 
              ${newsletterPlan === plan.index && styles.active}`}
            >
              <ul>
                <div className={styles.plans_header}>
                  <div>
                    <h3> {plan.title} </h3>
                  </div>
                  {/* <p className={styles.offer}>
                   {" "}
                    Gratuit
                    {plan.index === "BASIC"
                      ? "Gratuit"
                      : " Bientôt disponible"}
                  </p>*/}
                  <p className={styles.period}>
                    {" "}
                    {/*  {_("until")} 31/12/2023*/}
                  </p>

                  <div className={styles.check_radio}>
                    <input
                      type="radio"
                      name="radio"
                      checked={newsletterPlan === plan.index}
                      onChange={() => handleRadioChange(plan.index)}
                      disabled={isDisabled(plan.index)}
                      //disabled={plan.index !== "BASIC"}
                    />
                  </div>
                </div>
                <p className={styles.triangle} />

                <li>
                  {/*<Select
                    isDisabled={newsletterPlan !== plan.index}
                    styles={SELECT_STYLES}
                    options={frequencyTabs}
                    isSearchable={false}
                    isClearable={false}
                    value={
                      newsletterPlan === plan.index
                        ? frequency
                        : frequencyTabs[0]
                    }
                    onChange={e => setFrequency(e)}
                  />*/}
                  <p>
                    {plan.frequency_number} / {_("month")}
                  </p>
                </li>
                <li>
                  <p>{plan.recipients}</p>
                </li>
                <li>
                  <p>{plan.senders}</p>
                </li>
                <p className={styles.free_space} />

                <li>
                  <p> {plan.max_articles_fff} </p>
                  <span
                    className={classNames(
                      plan.articles_fff_state === _("by_choice")
                        ? styles.state_choice
                        : styles.state_predefined
                    )}
                  >
                    {plan.articles_fff_state}
                  </span>
                </li>
                <li>
                  {plan.index === "PERSONALIZED" ? (
                    <>
                      <p>{plan.max_my_articles}</p>
                      <span
                        className={classNames(
                          plan.my_articles_state === _("by_choice")
                            ? styles.state_choice
                            : styles.state_predefined
                        )}
                      >
                        {plan.my_articles_state}
                      </span>
                    </>
                  ) : (
                    <i className={`icon-ttp-close ${styles.close_icon}`} />
                  )}
                </li>
                <p className={styles.free_space} />
                <li>
                  <span>
                    {plan.logo && (
                      <i className={`icon-ttp-check ${styles.check_icon}`} />
                    )}{" "}
                  </span>
                </li>
                <li>
                  <p>{plan.mode}</p>
                  {/* <p
                    className={classNames(
                      plan.articles_fff_state === _("by_choice")
                        ? styles.state_choice
                        : styles.state_predefined
                    )}
                  >
                    {plan.articles_fff_state}
                  </p> */}
                </li>
              </ul>
            </div>
          ))}
        </div>
        <div />
      </div>
    </>
  );
};

export default PlansNewsletter;
